@import "../../global-styles";

.category-component-container {
	text-align: center;
	cursor: pointer;

	.category {
		position: relative;
		background-size: cover;
		background-position: center;
		height: 300px;
		display: flex;
		/*flex-direction: column;*/
		justify-content: center;
		align-items: center;
		border-radius: $br;
		overflow: hidden;

		@media (max-width: 640px) {
			height: 260px;
		}

		@media (max-width: 480px) {
			height: 200px;
		}

		@media (max-width: 430px) {
			height: 180px;
		}

		@media (max-width: 320px) {
			height: 130px;
		}

		img {
			position: absolute;
			height: 100%;
			width: 100%;
			object-fit: cover;
			object-position: center;
			z-index: -1;
			pointer-events: none;
		}

		button {
			background: $white;
			border-color: $white;
			visibility: hidden;
			transition: none;

			@media (max-width: 430px) {
				// font-size: 8px;
				// padding: 8px 18px;
				display: none;
			}
		}
	}

	h2 {
		padding-top: 16px;
		text-transform: uppercase;
		font-family: "Chalet";
		letter-spacing: 2px;
		font-weight: 100;
		font-size: $h-small;
		display: inline-block;

		@media (max-width: 640px) {
			font-size: $p3;
		}

		@media (max-width: 430px) {
			font-size: $p4;
		}
	}
}

.category-component-container:hover button {
	visibility: visible;
}

.category-component-container:nth-child(3) {
	h2 {
		max-width: 90%;

		@media (max-width: 1300px) {
			max-width: 100%;
		}
	}
}

.category-component-container:nth-child(4) {
	h2 {
		max-width: 85%;

		@media (max-width: 1300px) {
			max-width: 100%;
		}
	}
}

.category-component-container:nth-child(7) {
	h2 {
		max-width: 85%;

		@media (max-width: 1300px) {
			max-width: 100%;
		}
	}
}

/* The last container override */

.category-component-container:last-child {
	cursor: default;

	.category {
		position: relative;
		background: $yellow;
		border-radius: $br;
		cursor: pointer;
		overflow: visible;

		button {
			visibility: visible;
			position: relative;
			background: $clear;
			border: none;
			color: $clear;
		}
		button::after {
			content: "VIEW ALL PROJECTS";
			position: absolute;
			width: 100%;
			height: auto;
			height: 100px;
			top: 0;
			left: 0;
			font-size: $h-small;
			color: $black;
			background-image: url("../../assets/icons/arrow-right-light.svg");
			background-repeat: no-repeat;
			background-position: center bottom;
			background-size: 30px;
			transform: translateY(-20px);
			transition: all 0.1s linear;
			@media (max-width: 430px) {
				font-size: $p3;
				background-size: 20px;
				height: 80px;
			}
		}
	}

	.category:hover button::after {
		letter-spacing: 3px;

		@media (max-width: 430px) {
			letter-spacing: 2px;
		}
	}

	.category::before {
		content: "";
		position: absolute;
		width: 100vw;
		height: 100%;
		left: 0;
		background: $yellow;
		border-radius: $br;
	}

	h2 {
		display: none;
	}
}

.category-component-container:last-child .category > button {
	@media (max-width: 430px) {
		display: initial;
	}
}
