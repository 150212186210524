@import "../../global-styles";

.header-container * {
	transition: all 0.2s linear;
}

.header-container {
	position: relative;
	width: 100%;
	background: $clear;
	display: flex;
	justify-content: center;

	.header {
		width: 100%;
		max-width: $mw;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding: $pad;

		padding-top: calc($pad-tb + 40px);

		@media (max-width: 1500px) and (min-width: 431px) {
			padding-left: 40px;
			padding-right: 40px;
		}

		@media (max-width: 430px) {
			padding-top: $pad-tb;
		}
	}

	.logo-container {
		flex-grow: 1;
		flex-shrink: 0;
		max-width: 440px;

		@media (max-width: 920px) {
			max-width: 400px;
			flex-shrink: 1;
			flex-grow: 0;
			margin-right: 60px;
		}

		@media (max-width: 768px) {
			max-width: 280px;
		}

		@media (max-width: 370px) {
			max-width: 220px;
		}

		img {
			max-width: 100%;
		}

		.mobile {
			display: none;
		}

		@media (max-width: 768px) {
			.desktop {
				display: none;
			}
			.mobile {
				display: block;
			}
		}
	}

	.nav-container {
		position: relative;
		display: flex;
		justify-content: right;
		flex-grow: 0;
		flex-shrink: 1;
		padding-top: 4px;

		@media (max-width: 768px) {
			nav {
				display: none;
			}
		}

		.hamburger {
			display: none;
			width: 24px;
			cursor: pointer;
			margin-top: 7px;
			transition: transform 0.1s linear;

			@media (max-width: 768px) {
				display: block;
			}

			@media (max-width: 370px) {
				width: 24px;
				margin-top: 12px;
			}
		}

		ul {
			display: block;
			list-style-type: none;
			margin: 0;
			padding: 0;
			font-family: "Chalet", sans-serif;
			font-size: 16px;

			li:nth-of-type(2),
			li:nth-of-type(3) {
				margin-left: 40px;

				@media (max-width: 920px) {
					margin-left: 20px;
				}
			}

			li {
				display: inline;

				a,
				a:visited,
				a:active {
					text-decoration: none;
					color: $coal;
				}

				a:hover {
					text-decoration: underline;
					text-underline-offset: 4px;
					transition: none;
				}

				.active-link {
					text-decoration: underline;
					text-underline-offset: 4px;
				}
			}
		}

		img {
			display: none;
			width: 30px;
			cursor: pointer;
		}
	}
}

.mobile-menu {
	display: none;
	position: relative;
	width: 100%;
	height: 100vh;
	// display: flex;
	// flex-direction: row;
	// justify-content: space-between;
	// align-items: center;
	padding: $pad;
	padding-top: 0;
	padding-bottom: 0;
	background: $white;
	z-index: 99;

	nav {
		width: 100%;

		ul {
			list-style-type: none;
			margin: 0;
			padding: 0;
			font-family: "Chalet", sans-serif;
			font-size: 5vw;
			text-align: center;

			.bar {
				height: 2px;
				width: 100%;
				background: $black;
				margin: $spacer 0;
			}

			li {
				display: block;
				padding: 20px 0;

				a,
				a:visited,
				a:active {
					text-decoration: none;
					color: $black;
				}

				a:hover {
					text-decoration: underline;
					text-underline-offset: 8px;
					text-decoration-thickness: 2px;
				}

				.active-link {
					text-decoration: underline;
					text-underline-offset: 8px;
					text-decoration-thickness: 2px;
				}
			}
		}
	}

	.header-footer {
		display: flex;
		flex-direction: column;
		gap: 20px;
		margin-top: 80px;

		a,
		a:visited,
		a:active {
			text-decoration: none;
			color: $black;
			display: block;
			font-family: "Chalet";
			letter-spacing: 2px;
			font-size: $a-small;
			line-height: 1.5;
		}

		// .location {
		// }

		// .contact {
		// }

		.socials {
			display: flex;
			justify-content: flex-start;
			width: 50%;
			flex-grow: 0;
			flex-shrink: 1;

			a {
				line-height: 0;
				margin-right: 20px;
			}

			img {
				height: 20px;
			}
		}
	}
}

.mobile-gray {
	background: $light;
}
