@import "../../global-styles";

.project-viewer-container * {
	transition: all 0.2s linear;
}

.project-viewer-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	.project {
		width: 100%;
		max-width: $mw;
		padding: $pad;

		@media (max-width: 1500px) and (min-width: 431px) {
			padding-left: 40px;
			padding-right: 40px;
		}

		.carousel {
			display: flex;
			margin-bottom: $spacer;
			gap: 15px;

			@media (max-width: 768px) {
				flex-direction: column;
			}

			.featured {
				position: relative;
				width: 80%;
				height: 600px;

				@media (max-width: 768px) {
					width: 100%;
					max-height: 400px;
				}

				@media (max-width: 500px) {
					width: 100%;
					max-height: 300px;
				}

				@media (max-width: 430px) {
					width: 100%;
					max-height: 280px;
				}

				.arrow-container {
					position: absolute;
					top: 0;
					display: flex;
					align-items: center;
					justify-content: center;
					width: 40px;
					height: 100%;
					cursor: pointer;
					z-index: 99;

					@media (max-width: 430px) {
						display: none !important;
					}

					img {
						position: relative;
						max-width: 30px;
						width: 30px;
						height: auto;
						filter: invert(100%);
						opacity: 0.9;
						transition: none !important;
					}
				}

				.arrow-container-left {
					left: 0;
				}

				.arrow-container-left:hover {
					background: linear-gradient(
						to left,
						$clear,
						rgba(255, 255, 255, 0.3)
					);
				}

				.arrow-container-right {
					right: 0;
				}

				.arrow-container-right:hover {
					background: linear-gradient(
						to right,
						$clear,
						rgba(255, 255, 255, 0.3)
					);
				}

				.arrow-container:hover > img {
					opacity: 1;
				}

				img {
					width: 100%;
					max-width: 100%;
					height: 100%;
					object-fit: cover;
					object-position: center center;
					border-radius: $br;
					transition: none !important;
				}
			}

			.collection-container {
				position: relative;
				width: 20%;

				@media (max-width: 768px) {
					width: 100%;
				}

				.collection {
					display: flex;
					flex-direction: column;
					gap: 15px;
					width: 100%;
					height: 600px;
					max-height: 600px;
					overflow: scroll;
					scrollbar-width: none;

					&::-webkit-scrollbar {
						width: 0;
						height: 0;
					}
					border-radius: $br;

					@media (max-width: 768px) {
						flex-direction: row;
						height: auto;
					}

					img {
						width: 100%;
						max-width: 100%;
						min-height: 200px;
						height: 200px;
						max-height: 200px;
						object-fit: cover;
						object-position: center center;
						border-radius: $br;
						cursor: pointer;
						opacity: 0.5;

						@media (max-width: 768px) {
							min-width: 200px;
							width: 200px;
							max-width: 200px;
							min-height: 140px;
							height: 140px;
							max-height: 140px;
						}

						@media (max-width: 430px) {
							min-width: 120px;
							width: 120px;
							max-width: 120px;
							min-height: 100px;
							height: 100px;
							max-height: 100px;
						}
					}

					img.active {
						opacity: 1;
					}
				}

				.angle-container {
					position: absolute;
					left: 0;
					display: flex;
					justify-content: center;
					align-items: center;
					width: 100%;
					height: 30px;
					cursor: pointer;
					z-index: 99;

					@media (max-width: 768px) {
						left: auto;
						top: 0;
						width: 30px;
						height: 100%;
					}

					img {
						position: relative;
						width: 48px;
						filter: invert(100%);
						opacity: 0.9;
						pointer-events: none;

						@media (max-width: 768px) {
							max-height: 30px;
						}
					}
				}

				.angle-container:hover img {
					opacity: 1;
				}

				.angle-container-top {
					top: 0;

					@media (max-width: 768px) {
						left: 0;
					}

					img {
						transform: translateY(10px);

						@media (max-width: 768px) {
							transform: translateY(0) rotate(-90deg);
						}
					}
				}

				.angle-container-top:hover {
					background: linear-gradient(to top, $clear, rgba(255, 255, 255, 0.3));
				}

				.angle-container-bottom {
					bottom: 0;

					@media (max-width: 768px) {
						right: 0;
					}

					img {
						transform: translateY(-5px);

						@media (max-width: 768px) {
							transform: translateY(0) rotate(-90deg);
						}
					}
				}

				.angle-container-bottom:hover {
					background: linear-gradient(
						to bottom,
						$clear,
						rgba(255, 255, 255, 0.3)
					);
				}
			}
		}

		.project-info {
			max-width: 75%;
			@media (max-width: 850px) {
				max-width: 100%;
			}
			h2 {
				font-family: "Chalet";
				line-height: 1;
				font-weight: 100;
				text-transform: uppercase !important;
				letter-spacing: 4px;
				margin-bottom: 10px;
			}
			span {
				font-weight: 100;
				text-transform: uppercase;
				font-size: 14px;
				letter-spacing: 2px;
			}

			span:first-of-type {
				margin-right: 16px;
			}

			.project-description {
				margin: $spacer 0;
			}
		}
	}

	.pagination {
		width: 100%;
		max-width: $mw;
		padding: $pad;
		padding-top: 0;
		padding-bottom: 0;
		margin-top: $spacer;

		.bar {
			flex-grow: 0;
			flex-shrink: 1;
			height: 2px;
			width: 100%;
			background: $black;
			margin-bottom: 30px;
		}

		.paginationButtons {
			display: flex;
			justify-content: space-between;

			a,
			a:visited,
			a:active {
				color: $coal;
				text-decoration: none;

				button {
					display: flex;
					align-items: center;
					padding: 0;
					border: none;
					font-family: "Chalet";
					font-size: 16px;
					font-weight: 100;
					text-transform: uppercase !important;
					letter-spacing: 4px;
					border-radius: 0;
					width: auto;
					background: $white;
					color: $black;

					@media (max-width: 640px) {
						font-size: 12px;
					}

					@media (max-width: 390px) {
						font-size: 16px;
					}

					span {
						white-space: nowrap;

						&:last-of-type {
							display: none;

							@media (max-width: 390px) {
								display: initial;
							}
						}

						@media (max-width: 390px) {
							&:first-of-type {
								display: none;
							}
						}
					}

					img {
						width: 30px;

						@media (max-width: 640px) {
							max-width: 16px;
						}

						@media (max-width: 640px) {
							max-width: 20px;
						}
					}
				}
			}

			a:first-of-type img {
				margin-right: 15px;

				@media (max-width: 640px) {
					margin-right: 10px;
				}
			}

			a:last-of-type img {
				margin-left: 15px;

				@media (max-width: 640px) {
					margin-left: 10px;
				}
			}
		}
	}
}

.fade-in-left {
	animation: fadeInLeft 0.3s ease-in-out;
}

@keyframes fadeInLeft {
	0% {
		opacity: 0;
		transform: translateX(30px);
	}
	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

.fade-out-left {
	animation: fadeOutLeft 0.3s ease-in-out;
}

@keyframes fadeOutLeft {
	0% {
		opacity: 1;
		transform: translateX(0);
	}
	100% {
		opacity: 0;
		transform: translateX(-30px);
	}
}

/**/

.fade-in-right {
	animation: fadeInRight 0.3s ease-in-out;
}

@keyframes fadeInRight {
	0% {
		opacity: 0;
		transform: translateX(-30px);
	}
	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

.fade-out-right {
	animation: fadeOutRight 0.3s ease-in-out;
}

@keyframes fadeOutRight {
	0% {
		opacity: 1;
		transform: translateX(0);
	}
	100% {
		opacity: 0;
		transform: translateX(30px);
	}
}
