@import "../../global-styles";

.noPage {
	width: 100%;
	height: 50vh;
	max-width: $mw;
	padding: $pad;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 0 auto;

	h1 {
		position: relative;
		display: block;
		font-size: 10vw;

		&:before {
			content: "";
			position: absolute;
			width: 150%;
			height: 150%;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			background: $yellow;
			z-index: -1;
			-webkit-animation: move 25s infinite alternate;
			animation: move 6s infinite alternate;
			transition: 0.3s cubic-bezier(0.07, 0.8, 0.16, 1);
		}
	}
}
