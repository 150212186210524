@import "../../global-styles";

.home-component-container * {
	transition: all 0.2s linear;
}

.home-component-container {
	display: flex;
	justify-content: center;
	overflow-x: hidden;

	.home {
		width: $mw;
		max-width: $mw;
		padding: $pad;

		@media (max-width: 1500px) and (min-width: 431px) {
			padding: 30px 40px;
		}

		@media (max-width: 430px) {
			padding: 0;
		}

		.home-one {
			background-image: url("../../assets/projects/hospitality/Sage Valley Locker Room/dickinson-home-new.jpg");
			background-size: cover;
			background-repeat: no-repeat;
			background-position: 0 50%;
			height: 600px;
			border-radius: $br;
			overflow: hidden;
			margin-bottom: calc($spacer + 40px);

			@media (max-width: 640px) {
				height: 500px;
			}

			@media (max-width: 430px) {
				background-image: none;
				border-radius: 0;
				height: auto;
				margin-bottom: calc($spacer / 2);
			}

			.mobile-splash {
				display: none;
				background-image: url("../../assets/projects/hospitality/Sage Valley Locker Room/dickinson-home-new.jpg");
				background-size: cover;
				background-repeat: no-repeat;
				background-position: 0 50%;
				width: 100%;
				height: 300px;

				@media (max-width: 430px) {
					display: block;
				}
			}

			.splash {
				position: relative;
				display: flex;
				justify-content: center;
				align-items: center;
				width: 50%;
				height: 100%;
				background: $clear;
				// border-radius: 0 50% 0 0;
				// overflow: hidden;
				/*border-radius: 0 60% 25% 0;*/
				/*clip-path: ellipse(100% at 5% 90%);
	        clip-path: ellipse(100% 200% at 0% 100%);*/

				@media (max-width: 1020px) {
					width: 55%;
				}

				@media (max-width: 930px) {
					width: 65%;
				}

				@media (max-width: 800px) {
					width: 70%;
				}

				@media (max-width: 680px) {
					width: 80%;
				}

				@media (max-width: 550px) {
					width: 96%;
				}

				@media (max-width: 470px) {
					width: 100%;
				}

				@media (max-width: 430px) {
					height: auto !important;
					background: $yellow;
					padding: 20px 30px;
				}

				div {
					position: relative;
					z-index: 1;
					display: flex;
					flex-direction: column;
					justify-content: space-around;
					height: 80%;
					width: 80%;

					@media (max-width: 1200px) {
						height: 70%;
					}

					@media (max-width: 768px) {
						height: 65%;
					}

					@media (max-width: 690px) {
						height: 70%;
					}

					@media (max-width: 430px) {
						height: 100%;
						width: 100%;
						padding: 20px 0;
					}

					.philosophy-mobile {
						display: none;
						@media (max-width: 430px) {
							display: inline;
							margin-bottom: 20px;
						}
					}

					.philosophy {
						display: inline;
						@media (max-width: 430px) {
							display: none;
						}
					}

					h1 {
						font-size: $h-big;

						@media (max-width: 1200px) {
							font-size: 46px;
						}

						@media (max-width: 980px) {
							font-size: 46px;
						}

						@media (max-width: 768px) {
							font-size: 42px;
						}

						@media (max-width: 750px) {
							font-size: 38px;
						}

						@media (max-width: 640px) {
							font-size: 32px;
						}

						@media (max-width: 430px) {
							font-size: 38px;
							margin-bottom: 20px;
						}

						@media (max-width: 344px) {
							font-size: 34px;
						}
					}

					p {
						font-size: 24px;

						@media (max-width: 1200px) {
							font-size: 20px;
						}

						@media (max-width: 980px) {
							font-size: 20px;
						}

						@media (max-width: 750px) {
							font-size: 18px;
						}

						@media (max-width: 640px) {
							font-size: 16px;
						}

						@media (max-width: 455px) {
							font-size: 14px;
						}

						@media (max-width: 430px) {
							font-size: 20px;
						}

						@media (max-width: 400px) {
							font-size: 20px;
						}

						@media (max-width: 344px) {
							font-size: 18px;
						}
					}

					div {
						display: flex;
						flex-direction: row;
						justify-content: flex-start;
						height: auto;
						width: 100%;

						@media (max-width: 980px) {
							flex-direction: column;
							width: 60%;
						}

						@media (max-width: 768px) {
							width: 80%;
						}

						a:nth-of-type(1) {
							margin-right: 30px;

							@media (max-width: 980px) {
								margin-right: 0;
								margin-bottom: 10px;
							}
						}

						a button {
							@media (max-width: 980px) {
								width: 100%;
							}

							@media (max-width: 430px) {
								width: auto;
							}
						}

						a:nth-of-type(1) button {
							background: $black;
							color: $yellow;
						}

						a:nth-of-type(2) button {
							background: $clear;
							color: $black;

							// @media (max-width: 430px) {
							// 	display: none;
							// }
						}

						a {
							transition: all 0.1s linear;
						}

						a:hover {
							transform: scale(105%);

							@media (max-width: 430px) {
								transform: none;
							}
						}
					}
				}
			}

			.splash::before {
				content: "";
				position: absolute;
				top: 50%;
				left: 50%;
				// width: 200%;
				// height: 200%;
				width: 100%;
				height: 120%;
				transform: translateX(-63%) translateY(-50%);
				//transform: translateY(1%);
				background: $yellow;
				z-index: 0;
				border-radius: 0% 50% 13% 0%;
				//-webkit-animation: homeMove 25s infinite alternate;
				//animation: homeMove 25s infinite alternate;
				transition: 0.4s ease-in-out;

				@media (max-width: 1320px) {
					transform: translateX(-50%) translateY(-50%);
				}

				@media (max-width: 430px) {
					display: none;
				}
			}

			.splash:hover::before {
				border-radius: 0% 13% 50% 0%;
				// animation: homeMove 2s infinite alternate;
			}

			// @keyframes homeMove {
			// 	from {
			// 		height: 120%;
			// 	}

			// 	to {
			// 		height: 200%;
			// 	}
			// }

			// @-webkit-keyframes homeMove {
			// 	from {
			// 		border-radius: 10px;
			// 	}

			// 	to {
			// 		border-radius: 10px;
			// 	}
			// }
		}

		.home-two {
			@media (max-width: 430px) {
				padding: $pad;
			}
			.section-title {
				display: flex;
				flex-direction: row;
				align-items: center;
				margin-bottom: $spacer;

				@media (max-width: 430px) {
					margin-bottom: 30px;
				}

				h1 {
					flex-grow: 1;
					flex-shrink: 0;
					color: $black;
					font-size: $h-med;

					@media (max-width: 640px) {
						font-size: 32px;
					}

					@media (max-width: 430px) {
						font-size: 26px;
					}
				}

				.bar {
					flex-grow: 0;
					flex-shrink: 1;
					height: 2px;
					width: 100%;
					background: $black;
					margin-left: $spacer;

					@media (max-width: 640px) {
						margin-left: calc($spacer / 2);
					}
				}
			}
		}
	}
}
