@import "../../global-styles";

.directory-component-container {
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-template-rows: auto auto;
	grid-auto-flow: row;
	gap: $spacer;

	@media (max-width: 1160px) {
		grid-template-columns: 1fr 1fr 1fr;
	}

	@media (max-width: 850px) {
		grid-template-columns: 1fr 1fr;
	}

	@media (max-width: 640px) {
		gap: $spacer calc($spacer / 2);
	}
}
